<template>
  <div class="container" style="position: relative">
    <div v-if="!ifLgScreen" style="position: absolute;top: 10px;width: 100%;padding: 0 20px; box-sizing: border-box;display: flex;justify-content: space-between">
      <div @click="$router.push('/')">
        <img style="width: 22px;height: 22px; vertical-align: middle" src="../../assets/img/user/phone.svg" alt="">
        <span style="display: inline-block; vertical-align: middle">{{ $t('login.home_page') }}</span>
      </div>
      <div @click="$router.push('../download')">
        <span style="display: inline-block; vertical-align: middle">{{ $t('footer.download_app') }}</span>
        <img style="width: 22px;height: 22px; vertical-align: middle; transform: scale(-1.0)" src="../../assets/img/user/phone.svg" alt="">
      </div>
    </div>
    <div v-else  style="position: absolute;top:10px;display: flex;justify-content: space-between;padding:0 2%;box-sizing:border-box;width: 100%">
      <img style="width: 110px; height: 30px;" src="../../assets/img/logo_purple.png" alt="">
      <div>
        <img src="" alt=""><span @click="$router.push('/')" style="color: #AB54DB;cursor: pointer">{{ $t('login.back_homepage') }}</span>
      </div>
    </div>
    <div class="login" style="margin-top: 30px">
      <div class="title">{{ $t('login.create_felton_account') }}</div>
<!--      <div class="sub-title">Apply to your email or mobile phone number for registration</div>-->
      <div class="tab">
        <div @click="isActive=true;$refs.registerFormRef.resetFields()" :class="{'bg-change':isActive}">{{ $t('login.email') }}</div>
        <div @click="isActive=false;$refs.registerFormRef.resetFields()" :class="{'bg-change':!isActive}">{{ $t('login.mobile_phone') }}</div>
      </div>
      <div class="form">
        <el-form :model="registerForm" :rules="registerRules" ref="registerFormRef" @keyup.enter.native="handleRegister">
          <div class="form-group">
            <div v-if="isActive">
              <div class="label">{{ $t('login.email') }}</div>
              <el-form-item prop="email">
                <el-input v-model="registerForm.email" clearable :onkeyup="registerForm.email=registerForm.email.replace(/[, ]/g,'')" :placeholder="$t('login.input_email_number')"></el-input>
              </el-form-item>
            </div>
            <div v-else style="position: relative">
              <div class="label">{{ $t('login.mobile_phone') }}</div>
<!--              <el-form-item prop="area"  class="area">-->
<!--                <div style="position: absolute;top: 0;left: 10px;font-size: 20px;z-index: 100">+</div>-->
<!--                <el-input v-model="registerForm.area" style="width: 100px;" placeholder="国家区号"></el-input>-->
<!--              </el-form-item>-->
<!--              style="width: 225px;position: absolute;top:33.5px;right: 0"-->
              <el-form-item prop="phone" >
                <el-input v-model="registerForm.phone" clearable :onkeyup="registerForm.phone=registerForm.phone.replace(/[, ]/g,'')"  :placeholder="$t('login.input_phone')"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-group">
            <div class="label">{{ $t('login.password') }}</div>
            <el-form-item prop="password">
              <el-input v-model="registerForm.password" show-password :placeholder="$t('login.input_pwd')"></el-input>
            </el-form-item>
            <div class="label">{{ $t('login.authCode') }}</div>
            <el-form-item prop="auth_code"  style="position: relative">
              <el-input v-model="registerForm.auth_code" :placeholder="$t('login.input_code')" maxlength="6"></el-input>
              <div class="auth-code" v-if="isActive">
                <div v-if="isEmailSent" @click="sendAuthCode('email')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('login.send_code') }}</div>
                <div v-else><span>{{emailSeconds}}</span><span>{{ $t('login.resend_code') }}</span></div>
              </div>
              <div class="auth-code" v-else>
                <div v-if="isPhoneSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('login.send_code') }}</div>
                <div v-else><span>{{phoneSeconds}}</span><span>{{ $t('login.resend_code') }}</span></div>
              </div>
            </el-form-item>
          </div>
          <div class="form-group">
          <div class="label">{{ $t('login.recommender_ID') }} ({{ $t('login.optional') }})</div>
          <el-form-item prop="parent_invitation">
            <el-input v-if="$route.query.invitation!=undefined" disabled v-model="registerForm.parent_invitation" :placeholder="$t('login.input_invitation_code')"></el-input>
            <el-input v-else  v-model="registerForm.parent_invitation" :placeholder="$t('login.input_invitation_code')" maxlength="6"></el-input>
          </el-form-item>
<!--            <div class="label">verifyCode</div>-->
<!--            <el-form-item prop="img_auth_code"  style="position: relative">-->
<!--              <el-input v-model="registerForm.img_auth_code" placeholder="请输入图片上的信息"></el-input>-->
<!--&lt;!&ndash;              <img src="http://8.214.96.149:8899/api/index/getCaptcha" onclick="this.src='http://8.214.96.149:8899/api/index/getCaptcha?t='+Date.parse(new Date())" alt="" style="position: absolute;top: 8px;right: 8px;width: 60px;height: 25px;cursor: pointer">&ndash;&gt;-->
<!--              <img :src="imgUrl" @click="getImgAuthCode" alt="" style="position: absolute;top: 8px;right: 8px;width: 60px;height: 25px;cursor: pointer">-->
<!--            </el-form-item>-->
          </div>
        </el-form>

        <div class="agreement">
<!--          <input type="checkbox" v-model="registerForm.checkbox">-->

          <div><span style="cursor: pointer"><el-checkbox v-model="registerForm.checkbox">{{ $t('login.I_have_read_and_agree') }}</el-checkbox></span> <span style="color: #AB54DB;cursor: pointer" @click="$router.push('/robot/UserUsageProtocol')">{{ $t('login.terms_of_service_of_felton') }}</span></div>
        </div>
        <div class="btns">
          <button class="login-btn" @click="handleRegister">{{ $t('login.register_save') }}</button>
        </div>

      </div>
      <div class="links">
        <div>{{ $t('login.registered') }}</div>
        <div @click="toLogin">{{ $t('login.login') }}</div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  checkEmail,
  checkEmailGeneral,
  checkEmailOrIsExist,
  checkMobile,
  checkMobileGeneral, checkMobileOrIsExist,
  checkPassword
} from "../../utils/validate";
import {getAuthCode, imgAuthCode, toRegister} from "../../server/auth";
import {screenSize} from "../../utils/tools";

export default {
  name: "Register",
  data(){
    return{
      imgUrl:'',
      isActive:true,
      registerForm:{
        email:'',
        phone:'',
        password:'',
        auth_code:'',
        acounttype:'',
        captcha:'',
        parent_invitation:'',
        checkbox:false,
        area:86,
        img_auth_code:''
      },
      registerRules:{
        phone:[{ required: true, message: this.$t('login.input_phone'), trigger: 'blur' },
          {validator:checkMobileOrIsExist,trigger: 'blur'},],
        email:[{ required: true, message: this.$t('login.input_email'), trigger: 'blur' },
          {validator:checkEmailOrIsExist,trigger: 'blur'}],
        password:[{ required: true, message: this.$t('login.input_pwd'), trigger: 'blur' },
          {validator:checkPassword,trigger: 'blur'}],
        auth_code:[{ required: true, message: this.$t('login.input_code'), trigger: 'blur' }],
        // parent_invitation:{ required: true, message: '请输入上级推荐人', trigger: 'blur' },
        area:[{ required: true, message: this.$t('login.input_area_code'), trigger: 'blur' }],
        img_auth_code:[{ required: true, message: this.$t('login.input_check_code'), trigger: 'blur' }],
      },
    //  验证码的功能
      isPhoneSent:true,
      phoneSeconds:60,
      isEmailSent:true,
      emailSeconds:60,
      ifLgScreen:true
    }
  },
  created() {
    // this.$i18n.locale='en'
    this.ifLgScreen=screenSize(900)
   // this.getImgAuthCode()
   if (this.$route.query.invitation) this.registerForm.parent_invitation=this.$route.query.invitation
  },
  methods:{
    toLogin(){
      this.$router.push('/login')
    },
    //执行登录
    handleRegister(){
      if (!this.registerForm.checkbox) return this.$message.warning(this.$t('login.read_terms_of_service'))
       this.$refs.registerFormRef.validate( async valid=>{
        if(!valid) return this.$message.error(this.$t('login.confirm_whether_registrationinfo_correct'))
        let username='';let acounttype=1
         const {email,phone,area,password,img_auth_code,auth_code,parent_invitation}=this.registerForm
         if (this.isActive) username = email
         else{username = phone;acounttype=2}
         console.log(username,acounttype)
        const res = await toRegister(username,password,acounttype,img_auth_code,parent_invitation,auth_code)
        if (res.success === false) return this.$message.error(res.message)
        this.$message.success(this.$t('login.regist_success'))
         this.$store.state.isEmailRegister=this.isActive
        await this.$router.push('/login')
      })
    },
  //  发送数字验证码
    async sendAuthCode(kind){
      if (kind==='phone'){
        if (!checkMobileGeneral(this.registerForm.phone)||this.registerForm.area==='') return this.$message.error(this.$t('login.confirm_whether_phone_format_area_correct'))
        this.isPhoneSent=false
        const timer=setInterval(()=>{
          this.phoneSeconds--
          if (this.phoneSeconds===0){
            this.phoneSeconds=60
            this.isPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(1,2,this.registerForm.phone)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('login.code_sended_phone'))
      }
      else if (kind==='email'){
        if (!checkEmailGeneral(this.registerForm.email)) return this.$message.error(this.$t('login.input_right_email'))
        this.isEmailSent=false
        const timer=setInterval(()=>{
          this.emailSeconds--
          if (this.emailSeconds===0){
            this.emailSeconds=60
            this.isEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(1,1,this.registerForm.email)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('login.code_sended_email'))
      }
    },
    async getImgAuthCode(){
      const img=await imgAuthCode()
      this.imgUrl=window.URL.createObjectURL(img);
    }
  }
}
</script>

<style scoped>
.container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.container .login{
  width: 330px;
}
.title{
  font-size: 23px;
  font-weight: 600;
  color: #181E25;
  margin-bottom: 10px;
}
.sub-title{
  width: 311px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #181E25;
  line-height: 20px;
  margin-bottom: 36px;
}
.tab div{
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: #181E25;
  height: 27px;
  line-height: 27px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.tab div:nth-child(1){
  width: 82px;
}
.tab div:nth-child(2){
  width: 140px;
}
.bg-change{
  /*background: #F5F5F5;*/
  color: #AB54DB !important;
}

.form .form-group .label{
  font-size: 14px;
  font-weight: 400;
  color: #181E25;
  margin: 26px 0 14px 0;
}
.form .form-group input{
  width: 325px;
  height: 46px;
  border-radius: 2px;
  border: 1px solid #979797;
}
.form .agreement{
  margin: 16px 0 0 3px;
  display: flex;
  flex-wrap: nowrap;
}
.form .agreement div{
  vertical-align: top;
  display: inline-block;
  width: 335px;
  height: 21px;
  line-height: 21px;
  font-size: 10px;
  font-weight: 400;
  color: #181E25;
}
.btns{
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.login-btn{
  width: 329px;
  height: 51px;
  background: #AB54DB;
  border: none;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  margin-top: 22px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 2px;
  cursor: pointer;
}
.links div{
  display: inline-block;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #171717;
  line-height: 20px;
}
.links div:nth-child(2){
  color: #AB54DB;
  margin-left: 5px;
  cursor: pointer;
}
.auth-code{
  position: absolute;
  top:0px;
  right: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.auth-code>div>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  margin-right: 6px;
}
@media screen and (max-width: 600px){
  .container{
    height: auto;
    padding: 30px 0;
  }
}
/deep/ .area .el-input__inner{
  padding-left: 28px;
  padding-right: 0;
}

</style>
